.previous-queries {

}

.previous-queries__query {
  color: #3A3A3A;
  border-top: 1px solid #DEDEDE;
  margin: 0;
  padding: 16px 0;
  font-family: 'Open sans', sans-serif;
  font-size: 13px;
}

.previous-queries__query:nth-child(1) {
  border: 0;
}